import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import {  Table } from "antd";
import { getGames } from "../../../ApiServices/Apifun";
import { CiSettings } from "react-icons/ci";
import GameSettingModal from "./GameSettingModal";
import { TbCalendarStats } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import AddGameModal from "./AddGameModal";

const GameList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [openAddGameModal,setOpenAddGameModal]=useState(false)
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(setselectedTab("2"));
    dispatch(setHeading("GameList"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getList = () => {
    setLoading(true);
    getGames()
      .then((res) => {
        if (res.status) {
          setData(
            res.data.map((i, index) => {
              return { ...i, sno: index + 1 };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getList();
  }, []);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Game Slug",
      dataIndex: "game_slug",
      key: "game_slug",
      render: (text) => <p className="">{text ?? "N/A"}</p>,
    },
    {
      title: "Game Type",
      dataIndex: "game_type",
      key: "game_type",
      render: (text) => <p className="capitalize">{text ?? "N/A"}</p>,
    },
    {
      title: "Game Name",
      dataIndex: "game_name",
      key: "game_name",
      render: (text) => <p className="capitalize">{text.split("_").join(" ") ?? "N/A"}</p>,
    },
    {
      title: "Game Status",
      dataIndex: "game_status",
      key: "game_status",
      render: (text) => (
        <div className="flex justify-center items-center">
          {text ? (
            <p className="bg-green-700 px-8 text-white font-semibold text-center">
              Active
            </p>
          ) : (
            <p className="bg-red-700 px-8 text-white font-semibold text-center">
              Inactive
            </p>
          )}
        </div>
      ),
    },
    {
      title: "Setting",
      dataIndex: "settingss",
      key: "settingss",
      render: (text, record) => (
        <div
          onClick={() => {
            setOpenSettingModal(true);
            setDetail(record);
          }}
          className="flex justify-center items-center"
        >
          <CiSettings
            className="bg-primary w-7 h-7 p-1"
            color="white"
            size={15}
          />
        </div>
      ),
    },
    {
      title: "CurrentRoomStats",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <NavLink to={"/current-room-stats"} state={record}>
          <div className="flex justify-center items-center">
            <TbCalendarStats
              className="bg-primary w-7 h-7 p-1"
              color="white"
              size={5}
            />
          </div>
        </NavLink>
      ),
    },
    {
      title: "Room Details",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <NavLink to={"/room-detail"} state={record}>
          <div className="flex justify-center items-center">
            <TbListDetails
              className="bg-primary w-7 h-7 p-1"
              color="white"
              size={5}
            />
          </div>
        </NavLink>
      ),
    },
  ];
  return (
    <>
      <div className="mt-4">
        {/* <div className="flex my-2  justify-end items-center">
          <Button
          onClick={() => {
            setOpenAddGameModal(true);
          }}
            style={{ color: "white" }}
            className="bg-primary border-none hover:border-none "
          >
            Add Game
          </Button>
        </div> */}
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={data}
        />
      </div>
      {openSettingModal && (
        <GameSettingModal
          apiCall={getList}
          open={openSettingModal}
          setIsOpen={setOpenSettingModal}
          record={detail}
        />
      )}
       {openAddGameModal && (
        <AddGameModal
          apiCall={getList}
          open={openAddGameModal}
          setIsOpen={setOpenAddGameModal}
        />
      )}
    </>
  );
};

export default GameList;
