import { NavLink } from "react-router-dom";

import { GiGamepad } from "react-icons/gi";
import { PiMoneyBold } from "react-icons/pi";

import {  MdDashboard, MdPayments } from "react-icons/md";
import { FaBell, FaRupeeSign } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";

export const Menuitems = () => {
  const list = [
    {
      key: "1",
      icon: <MdDashboard/>,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "6",
      icon: <FaUser />,
      label: (
        <NavLink to={"/user-list"}>
          <p>User List</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      icon: <GiGamepad  />,
      label: (
        <NavLink to={"/game-list"}>
          <p>Games</p>
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <MdPayments />,
      label: (
        <NavLink to={"/payment-setting"}>
          <p>Payment Setting</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      icon: <FaRupeeSign />,
      label: (
        <NavLink to={"/fund-request"}>
          <p>Fund Request</p>
        </NavLink>
      ),
    },
    {
      key: "5",
      icon: <PiMoneyBold />,
      label: (
        <NavLink to={"/withdrawal-request"}>
          <p>Withdrawal Request</p>
        </NavLink>
      ),
    },
    {
      key: "7",
      icon: <PiMoneyBold />,
      label: (
        <NavLink to={"/comission"}>
          <p>Referal Comission</p>
        </NavLink>
      ),
      
    },
    // {
    //   key: "8",
    //   icon: <FaBell />,
    //   label: (
    //     <NavLink to={"/notification"}>
    //       <p>Notification</p>
    //     </NavLink>
    //   ),
      
    // },
  ];
  return list;
};
