import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { getDashboardData } from "../../../ApiServices/Apifun";
import { Skeleton } from "antd";
import { FaUser } from "react-icons/fa";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState([
    {
      totalBetAmount: 0,
    },
  ]);
  const [otherData, setOtherData] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);
  const getDashboardCount = () => {
    setShowSkeleton(true);
    getDashboardData()
      .then((res) => {
        if (res.status) {
          setOtherData({
            "total User": res.totalUser,
            "Withdrawal Amount": res.WithdrawalAmount,
            "fundRequest Amount": res.fundRequestAmount,
          });
          if (res.totalBetAmount) {
            setCount(res.totalBetAmount);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowSkeleton(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("1"));
    dispatch(setHeading("Dashboard"));
    getDashboardCount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showSkeleton ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div className="  h-[100px]   w-full">
              <Skeleton.Button
                active={true}
                size={200}
                shape={"default"}
                block={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {count?.map((key, index) => {
            return (
              <div
                key={index}
                className=" shadow-lg hover:bg-gray-400 transition-all cursor-pointer shadow-gray-300 bg-white p-4 w-full"
              >
                <p className="flex justify-center items-center font-bold text-xl text-primary">
                  {"Total Bet Amount"}
                </p>
                <div className="flex text-lg mt-4 justify-between items-center">
                  <p className="font-semibold capitalize">
                    {key?.totalBetAmount}
                  </p>
                  <FaUser />
                </div>
              </div>
            );
          })}
          {Object.keys(otherData).map((key, index) => (
            <div
              key={index}
              className=" shadow-lg hover:bg-gray-400 transition-all cursor-pointer shadow-gray-300 bg-white p-4 w-full"
            >
              <p className="flex capitalize justify-center items-center font-bold text-xl text-primary">
                {key}
              </p>
              <div className="flex text-lg mt-4 justify-between items-center">
                <p className="font-semibold capitalize">{otherData[key]}</p>
                <FaUser />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Dashboard;
