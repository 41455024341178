import {  FormDataPost, Get, Patch, Post, Put, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};

export const userLogout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data, false);
};
export const createGames = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.createGame, param);
  return Post(url,data);
};
export const getDashboardData = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.dashboardCount, param);
  return Get(url);
};
export const getGames = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getGameList, param);
  return Get(url);
};
export const updateGame = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateGame, param);
  return Put(url,data);
};
export const addBank = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addBankDetail, param);
  return FormDataPost(url,data);
};
export const updateBank = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateBankDetail, param);
  return Put(url,data);
};
export const fetchBank = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchBankDetail, param);
  return Get(url);
};
export const fetchFundReq = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchFundReq, param);
  return Get(url);
};
export const fundReqAction = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fundReqAction, param);
  return Put(url,data);
};
export const getWithdrawalReq = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchWithdrawalReq, param);
  return Get(url);
};
export const updateWithdrawalReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateWithdrawalReq, param);
  return Put(url,data);
};

export const GetCurrentRoomStats = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.currentRoomStats, param);
  return Get(url);
};
export const GetRoomDetails = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.roomDetails, param);
  return Get(url);
};
export const ChooseWinner = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.chooseWinner, param);
  return Post(url,data);
};

export const GetUserList = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.userList, param);
  return Get(url);
};

export const UpdateUser = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateUser, param);
  return Patch(url,data);
};
export const GetUserBettings = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.userBettings, param);
  return Get(url);
};
export const GetUserWalletTxn = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.walletTxn, param);
  return Get(url);
};


export const GetPaymentSetting = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getPaymentSetting, param);
  return Get(url);
};

export const updatePaymentSetting = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updatePaymentSetting, param);
  return Put(url,data);
};
export const colorOption = (param = "") => {
  const url = getAPIUrl(Globalendpoints.colorOption, param);
  return Get(url);
};

export const passwordChange = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.changePassword, param);
  return Put(url,data);
};

export const GetNotification = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getNotification, param);
  return Get(url);
};

export const createNotification = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.createNotification, param);
  return Post(url,data);
};

export const updateNotification = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateNotification, param);
  return Put(url,data);
};