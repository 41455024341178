import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading } from "../../../Store/B2bslice";
import { FaUnlockAlt } from "react-icons/fa";
import { Button, Form, Input } from "antd";
import { passwordChange } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";

const ChangePassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const formref=useRef()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeading("Change Password"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
//   const validatePassword = (rule, value) => {
//     const { getFieldValue } = form;
//     if (value && value !== getFieldValue("newPassword")) {
//       return Promise.reject("The passwords do not match");
//     }
//     return Promise.resolve();
//   };
  const handleSubmit = (val) => {
    setBtnLoading(true);
    passwordChange({
      email: val.email,
      newPassword: val.newPassword,
    })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          formref.current.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-background h-full justify-center items-center">
        <div className="shadow-xl shadow-gray-500 w-[300px]">
          <div className="bg-primary h-[100px] flex justify-around items-center text-white">
            <p>
              <FaUnlockAlt size={30} />
            </p>
            <p className="text-lg font-bold">Change Password</p>
          </div>
          <div className="p-4">
            <Form form={form} ref={formref}
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleSubmit(val)}
            >
              <Form.Item label="Email" labelCol={{span:24}}
                rules={[
                  {
                    required: true,
                    message: "Please enter Email I'd",
                  },
                ]}
                className="w-full"
                name={"email"}
              >
                <Input  placeholder="Enter Email" />
              </Form.Item>
              <Form.Item label="Password" labelCol={{span:24}}
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
                name={"newPassword"}
              >
                <Input  placeholder="Enter password" />
              </Form.Item>
              {/* <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please confirm new  password",
                  },
                  { validator: validatePassword },
                ]}
                dependencies={["newPassword"]}
                name={"confirmNewPassword"}
              >
                <Input className="mt-2" placeholder="Enter confirm password" />
              </Form.Item> */}
              <Form.Item className="w-full" name={"password"}>
                <Button
                  loading={btnLoading}
                  style={{ color: "white" }}
                  className="w-full mt-4 hover:border-none focus:border-none bg-btnColor"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
