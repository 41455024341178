import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import {
  GetPaymentSetting,
  updatePaymentSetting,
} from "../../../ApiServices/Apifun";
import { Button, Form, Input } from "antd";

const Comission = () => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const formref = useRef();
  const [form] = Form.useForm();
  const [btnLoading2, setBtnLoading2] = useState(false);
  const [comission, setCommision] = useState(null);
  const [secondCommission, setSecondComission] = useState(null);

  const getCommmision = () => {
    GetPaymentSetting()
      .then((res) => {
        if (res.status) {
          setCommision(res.data[0].commission);
          setSecondComission(res.data[0].second_level_commission_amount);
          formref.current.setFieldsValue({
            max_fund_amount: res.data[1].max_fund_amount ?? 0,
            min_fund_amount: res.data[1].min_fund_amount ?? 0,
            max_withdrawal_amount: res.data[1].max_withdrawal_amount ?? 0,
            min_withdrawal_amount: res.data[1].min_withdrawal_amount ?? 0,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dispatch(setselectedTab("7"));
    dispatch(setHeading("Commission & Payment Settings"));

    getCommmision();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSave = () => {
    setBtnLoading(true);
    updatePaymentSetting({
      commission: Number(comission),
      second_level_commission_amount: Number(secondCommission),
    })
      .then((res) => {
        if (res.status) {
          GetPaymentSetting();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleFinish = (val) => {
    setBtnLoading2(true);
    updatePaymentSetting(val)
      .then((res) => {
        if (res.status) {
          GetPaymentSetting();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading2(false));
  };
  return (
    <>
      <div className="flex justify-center items-center ">
        <div className="bg-primary w-full p-4">
          <p className="text-white">Comission</p>
          <div className="flex justify-around w-full md:w-1/2 items-center gap-2">
            <Input
              value={comission}
              onChange={(e) => setCommision(e.target.value)}
              placeholder="Enter comission"
              className="border text-xs mt-2 rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <p className="text-white">Second Level Comission</p>
          <div className="flex justify-around w-full md:w-1/2 items-center gap-2">
            <Input
              value={secondCommission}
              onChange={(e) => setSecondComission(e.target.value)}
              placeholder="Enter second level comission"
              className="border text-xs mt-2 rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <div className="flex justify-end items-center">
            <Button
              disabled={!comission || secondCommission === ""}
              loading={btnLoading}
              onClick={handleSave}
              style={{ color: "white" }}
              className="h-10 text-white bg-black mt-2 border-none"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-4 items-center ">
        <div className="bg-primary w-full p-4">
          <p className="text-white">Payment setting</p>
          <div className="">
            <Form
              ref={formref}
              form={form}
              onFinish={(val) => handleFinish(val)}
            >
              <div className="grid grid-cols-2 md:grid-cols-4 place-items-center gap-4">
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Max Fund Request"
                  name={"max_fund_amount"}
                  rules={[
                    {
                      required: true,
                      message: "Max Fund Req. is Required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Enter Max Fund Req."
                    className="border text-xs  rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Min Fund Request"
                  name={"min_fund_amount"}
                  rules={[
                    {
                      required: true,
                      message: "Min Fund Req. is Required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Enter Min Fund Req."
                    className="border text-xs  rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Max Withdrawal amount"
                  name={"max_withdrawal_amount"}
                  rules={[
                    {
                      required: true,
                      message: "Max Withdrawal amount is Required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Enter Max Withdrawal amount"
                    className="border text-xs  rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Min Withdrawal Amount"
                  name={"min_withdrawal_amount"}
                  rules={[
                    {
                      required: true,
                      message: "Min Withdrawal Amount. is Required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input
                    placeholder="Enter Min Withdrawal Amount"
                    className="border text-xs  rounded-md outline-none border-gray-500 h-10 w-full focus:outline-none hover:outline-none"
                  />
                </Form.Item>
              </div>
              <div className="flex justify-end items-center">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={btnLoading2}
                    style={{ color: "white" }}
                    className="h-10 text-white bg-black mt-2 border-none"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comission;
