import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { GetRoomDetails } from "../../../ApiServices/Apifun";
import { Pagination, Table } from "antd";
import { useLocation } from "react-router-dom";

const RoomDetail = () => {
    const location = useLocation();
  const { game_type } = location.state || {};
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [current, setCurrent] = useState(1);
    const [roomDetail, setRoomDetail] = useState([]);
    const getRooms = () => {
      setLoading(true);
      GetRoomDetails(`?type=${game_type}&page=${current}&limit=10`)
        .then((res) => {
          if (res.status) {
            setTotalPages(res.pagination.totalDocuments);
            setRoomDetail(
              res.roomDetails.map((i, index) => {
                return {
                  ...i,
                  sno: index + 1,
                };
              })
            );
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
    useEffect(() => {
      dispatch(setselectedTab("2"));
      dispatch(setHeading("Room Detail"));
      getRooms();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);
    const columns = [
      {
        title: "Sr. No.",
        dataIndex: "sno",
        key: "sno",
      },
      {
        title: "Room Id",
        dataIndex: "room_id",
        key: "room_id",
        render: (text) => <p>{text}</p>,
      },
      {
        title: "Room Start Time",
        dataIndex: "room_start_time",
        key: "room_start_time",
        render: (text) => <p className="w-[130px]">{new Date(text).toLocaleString().split(",").join(" ")}</p>,
      },
      {
        title: "Room Close Time",
        dataIndex: "room_close_time",
        key: "room_close_time",
        render: (text) => <p className="w-[130px]">{new Date(text).toLocaleString().split(",").join(" ")}</p>,
      },
      {
        title: "Room Bet Close Time",
        dataIndex: "room_bet_close_time",
        key: "room_bet_close_time",
        render: (text) => <p className="w-[130px]">{new Date(text).toLocaleString().split(",").join(" ")}</p>,
      },
      {
        title: "Status",
        dataIndex: "room_status",
        key: "room_status",
        render: (text) =>
          !text ? (
            <p className="bg-green-600 text-white text-center p-1 rounded-sm">
              Active
            </p>
          ) : (
            <p className="bg-red-600 text-white text-center p-1 rounded-sm">
              InActive
            </p>
          ),
      },
      {
        title: "Winner Option",
        dataIndex: "winner_option",
        key: "winner_option",
        render: (text,record) => (
          <p
            className={`text-center ${
              record?.result?.option_name === "Red"
                ? "bg-red-600 text-white"
                : record?.result?.option_name === "Voilet"
                ? "bg-violet-700 text-white"
                : record?.result?.option_name === "Green"
                ? "bg-green-600 text-white"
                : "text-black"
            } rounded-md p-1`}
          >
            {record?.result?.option_name}
          </p>
        ),
      },
    //   {
    //     title: "Action",
    //     dataIndex: "txn.",
    //     key: "txn.",
    //     render: (text, record) => (
    //       <p>
    //         <AiFillEye
    //           className="bg-primary text-white flex justify-center items-center p-2"
    //           size={25}
    //         />
    //       </p>
    //     ),
    //   },
    ];
    return (
      <>
        <div className="mt-4">
          <Table
            className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
            columns={columns}
            loading={loading}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                className: "cursor-pointer text-[12px]",
              };
            }}
            dataSource={roomDetail}
          />
          <div className="mt-2 mb-8 flex justify-end items-center">
            <Pagination
              showQuickJumper={false}
              defaultCurrent={current}
              pageSize={10}
              current={current}
              total={totalPages}
              onChange={(page, pageString) => setCurrent(page)}
            />
          </div>
        </div>
      </>
    );
}

export default RoomDetail